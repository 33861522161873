import React from 'react';
import './Contact.scss';
import MyForm from './Form/Form'

const Contact = (props) =>{
    return(
        <div className='contact-wrapper' ref={props.refProp}>
          <div className='title-section'>CONTACT</div>
          <MyForm/>
          <div className='social-contact-wrapper'>
              <div><a href="https://www.linkedin.com/in/pranita-bhagat/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></div>
              <div><a href="https://github.com/Pranitab" target="_blank" rel="noopener noreferrer"><i className="fa fa-github"></i></a></div>
              <div><a href="mailto:Pranitabhagat91@gmail.com"><i className="fa fa-at"></i></a></div>
              <div><a href="https://twitter.com/PranitaBhagat5" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></div>
          </div>
        </div>
    )
}

export default Contact;