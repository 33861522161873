import ReactDom from 'react-dom';
import './Modal.scss';

const Modal = (props)=>{
    return ReactDom.createPortal(
        props.children,
        document.getElementById('portal')
    )
}

export default Modal;