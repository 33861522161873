import React from 'react';
import './About.scss';
//import myResume from '../../docs/Pranita-resume.docx';

const About = (props) =>{
    return(
        <div className='about-info' ref={props.refProp}>
            <div className='title-section'>ABOUT</div>
            <div className='self-img'></div>
            <div className='self-description'>
            Hello, I am a front end developer from California(USA). I love working with React and other hip frameworks. E-learning, E-commerce and Healthcare are the domains which I have explored during my job.<br/><br/>I don’t like to define myself by the work I’ve done. I define myself by the work I want to do. Skills can be taught, personality is inherent. I prefer to keep learning, continue challenging myself, and do interesting things that matter.<br/><br/><span className='imp-txt'>Feel free to connect with me...!</span>
            </div>
        </div>
    )
}

export default About;