import React from 'react';
import MediaQuery from 'react-responsive';
import './PersonalInfo.scss';
import About from '../About/About';
import Projects from '../Projects/Projects';
import Contact from '../Contact/Contact';
import Skills from '../Skills/Skills';

class PersonalInfo extends React.Component{
 
    constructor(props){
        super(props);
        this.aboutref=React.createRef();
        this.skillsref=React.createRef();
        this.projectsref=React.createRef();
        this.contactref=React.createRef();

        this.state={
            about:true,
            skills:false,
            projects:false,
            contact:false,
            mobileMenu:false,
        }
    }

    isInViewport = () => {


        //=====================in about ref=======================//
       // console.log('about data',this.aboutref.current.getBoundingClientRect().top,this.aboutref.current.getBoundingClientRect().height)
        if(this.aboutref.current.getBoundingClientRect().top <= 45 && this.aboutref.current.getBoundingClientRect().top > Number(0 - this.aboutref.current.getBoundingClientRect().height) ){
            //console.log("Element is in about");
            this.setState({about:true});
        }else{
           // console.log("Element is outside about");
            this.setState({about:false});
        }

         //=====================in skills ref=======================//
        // console.log('skills data',this.skillsref.current.getBoundingClientRect().top,this.skillsref.current.getBoundingClientRect().height)
         if(this.skillsref.current.getBoundingClientRect().top <= 5 && this.skillsref.current.getBoundingClientRect().top > Number(0 - this.skillsref.current.getBoundingClientRect().height) ){
             //console.log("Element is in skills");
             this.setState({skills:true});
         }else{
            // console.log("Element is outside skills");
             this.setState({skills:false});
         }

        //=====================in projects ref=======================//
        // console.log('projects data',this.projectsref.current.getBoundingClientRect().top,this.projectsref.current.getBoundingClientRect().height)
         if(this.projectsref.current.getBoundingClientRect().top <= 5 && this.projectsref.current.getBoundingClientRect().top > Number(0 - this.projectsref.current.getBoundingClientRect().height) ){
             //console.log("Element is in projects");
             this.setState({projects:true});
         }else{
            // console.log("Element is outside projects");
             this.setState({projects:false});
         }

        //=====================in contact ref=======================//
       //  console.log('contact data',this.contactref.current.getBoundingClientRect().top,this.contactref.current.getBoundingClientRect().height)
         if(this.contactref.current.getBoundingClientRect().top <= 5 && this.contactref.current.getBoundingClientRect().top > Number(0 - this.contactref.current.getBoundingClientRect().height) ){
             //console.log("Element is in contact");
             this.setState({contact:true});
         }else{
             //console.log("Element is outside contact");
             this.setState({contact:false});
         }
    };
    executeScroll = (refPointer,pointer) => {
        refPointer.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })
      // console.log(refPointer.current.getBoundingClientRect(),'refPointer');
       this.setState({
                about:false,
                skills:false,
                projects:false,
                contact:false
            })
       this.setState({[pointer]:true});
       this.setState({mobileMenu:!this.state.mobileMenu});     
    }  
    backToTop = ()=>{
        this.aboutref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" });
        this.setState({about:true});
    }
    onBurgerClick = () =>{
        this.setState({mobileMenu:!this.state.mobileMenu});
    }
   
    render(){
       // console.log(this.props.turnSurface,'turnsurface');
    return(
        <React.Fragment>
            <div className='backface' onScroll={(e)=>this.isInViewport(e)}>       
                <div className='header-patch'>
                    <MediaQuery maxWidth={600}>
                        <i className="fa fa-bars mobile-hamburger" onClick={this.onBurgerClick}></i>
                        {this.state.mobileMenu ? 
                        (<ul className='header-patch-inner'>
                            <li className={'header-inner '+(this.state.about?'selected':null)} onClick={()=>this.executeScroll(this.aboutref,'about')}>ABOUT</li>
                            <li className={'header-inner '+(this.state.skills?'selected':null)} onClick={()=>this.executeScroll(this.skillsref,'skills')}>SKILLS</li>
                            <li className={'header-inner '+(this.state.projects?'selected':null)} onClick={()=>this.executeScroll(this.projectsref,'projects')}>PROJECTS</li>
                            <li className={'header-inner '+(this.state.contact?'selected':null)} onClick={()=>this.executeScroll(this.contactref,'contact')}>CONTACT</li>
                        </ul>):null}
                    </MediaQuery>
                    <MediaQuery minWidth={600}>
                    <ul className='header-patch-inner'>
                        <li className={'header-inner '+(this.state.about?'selected':null)} onClick={()=>this.executeScroll(this.aboutref,'about')}>ABOUT</li>
                        <li className={'header-inner '+(this.state.skills?'selected':null)} onClick={()=>this.executeScroll(this.skillsref,'skills')}>SKILLS</li>
                        <li className={'header-inner '+(this.state.projects?'selected':null)} onClick={()=>this.executeScroll(this.projectsref,'projects')}>PROJECTS</li>
                        <li className={'header-inner '+(this.state.contact?'selected':null)} onClick={()=>this.executeScroll(this.contactref,'contact')}>CONTACT</li>
                    </ul>
                    </MediaQuery>
                </div>
        <div className='page-body'>
            <About refProp={this.aboutref}/>
            <Skills refProp={this.skillsref}/>
            <Projects refProp={this.projectsref}/>
            <Contact refProp={this.contactref}/>
            <div className='backToTop' onClick={this.backToTop}><i className="fa fa-angle-double-up"></i></div>
            <div className='copy-right'>PRANITA BHAGAT <i className="fa fa-copyright">2021</i></div>
        </div>
        
        </div>
        
        </React.Fragment>
    )
    }
}

export default PersonalInfo;