import React from 'react';
import './Projects.scss';
import ProjectTile from './ProjectTile/ProjectTile';

const ProjectsData=[{
  name:'Fabindia',
  img:require('../../images/fabindia.jpg'),
  hoverContent:'It is an E-commerce site of clothing brand in India',
  link:'https://www.fabindia.com',
  imgContainer:'fabindia',
  slideImg:['img1','img2','img3','img4','img5'],
  projectInfo:'It is an online store developed by using Oracle Commerce Cloud(OCC).In this I worked on complex functionality like zero redirect checkout page using paypal, Wishlist,api development and integration using nodeJS, product filter functionality and many more.'
},{
  name:'Interactive Science Lessons',
  img:require('../../images/isl.jpg'),
  hoverContent:'Creation of Interactive Lessons for Kids Learning',
  link:'https://www.sciencea-z.com/main/InteractiveLesson',
  imgContainer:'isl',
  slideImg:['img1','img2','img3','img4','img5','img6','img7','img8','img9','img10','img11','img12','img13','img14','img15','img16'],
  projectInfo:'Interactive Science virtual lessons provide a road map for students as they explore important and sometimes complex science ideas using multiple activities. I worked on lesson development, simulation activity developement,I was handling entire support project and clients shell level change requests.'
},{
  name:'Kornferry',
  img:require('../../images/kornferry.jpg'),
  hoverContent:'Employees behavioral training course',
  link:'https://www.kornferry.com/insights/learning',
  imgContainer:'kornferry',
  slideImg:['img1','img2','img3','img4'],
  projectInfo:"Kornferry's entire eGrid module was developed by me. I have worked on custom audio player/video player, interrelated drag and drop activities."
},{
  name:'Headsprout',
  img:require('../../images/headsprout.jpg'),
  hoverContent:'Interactive episodes for students',
  link:'https://www.headsprout.com',
  imgContainer:'headsprout',
  slideImg:['img1','img2','img3','img4','img5','img6','img7','img8'],
  projectInfo:'Headsprout offers interactive online episodes to teach K-5 students the reading fundamentals and comprehension skills.Worked on episodes and template development, also handled the support project'
},{
  name:'Classroom Complete Press',
  img:require('../../images/ccp.jpg'),
  hoverContent:'Mathematics ready-made educational lessons',
  link:'https://classroomcompletepress.com/collections/mathematics',
  imgContainer:'ccp',
  slideImg:['img1','img2','img3','img4','img5','img6','img7'],
  projectInfo:'It includes real-world word problems and drill activities for Number & Operations, Algebra, Geometry, Measurement, and Data Analysis & Probability.Worked as a lead in math module.'
},{
  name:'Tata Class Edge',
  img:require('../../images/tce.png'),
  hoverContent:'Educational lessons development',
  link:'https://www.tataclassedge.com/',
  imgContainer:'tce',
  slideImg:['img1','img2','img3','img4'],
  projectInfo:'Tata ClassEdge provides world-class service support to many schools.In this I worked on template development, interactive part development using jQuery.'
}]



const Projects = (props)=> {
    return(
        <div className='project-wrapper' ref={props.refProp}>
          <div className='title-section'>PROJECTS</div>
          <div className='project-tile-wrapper'>
            {
              ProjectsData.map((elem,i)=>{
                //console.log(elem,'elem>>>')
                return(
                <ProjectTile tileData={elem} key={elem.name}/>
                )
              })
            }
          </div>
        </div>
    )
    
}

export default Projects;