import React from 'react';
import './Skills.scss';

const skillsData =[
{
    name:'ReactJS',
    efficiancy:85
},
{
    name:'Redux',
    efficiancy:85
},
{
    name:'Javascript',
    efficiancy:85
},
{
    name:'HTML5',
    efficiancy:90
},
{
    name:'CSS3',
    efficiancy:90
},
{
    name:'jQuery',
    efficiancy:70
},
{
    name:'SASS',
    efficiancy:70
},{
    name:'JSON',
    efficiancy:75
},{
    name:'UI Design',
    efficiancy:90
},
{
    name:'KnockoutJS',
    efficiancy:70
},{
    name:'NodeJS',
    efficiancy:50
},{
    name:'OCC',
    efficiancy:50
},{
    name:'Mongo DB',
    efficiancy:50
}]
const Skills = (props) =>{
    //console.log(skillsData,'skillsData');
    return(
        <div className='skills-wrapper' ref={props.refProp}>
            <div className='title-section'>SKILLS</div>
            <div className='quote-section'>“Life without knowledge is death in disguise.”<br/>- Talib Kweli</div>
            {
                skillsData.map((elem,i)=>{
                    return (
                    <div className='skills-bar' key={elem.name}>
                        <div className='skills-bar-name'>{elem.name}</div>
                        <div className='skills-bar-progress'  key = {{i}}>
                            <div className='skills-bar-progress-inner' style={{width:elem.efficiancy+'%'}}>
                                {elem.efficiancy}%
                            </div>
                        </div>
                    </div>
                    )
                })
            }
            
        </div>
    )
}

export default Skills;