import React from 'react';
import './ProjectTile.scss';
import Modal from '../Modal/Modal';
import SimpleImageSlider from "react-simple-image-slider";
import MediaQuery from 'react-responsive';


class ProjectTile extends React.Component{
    constructor(props){
        super(props);
        this.state={
            active:false,
            showModal:false
        }
    }
    onTileMouseOver(data,event){
       // console.log('on mouseover...!');
        this.setState({active:true});
        }
        onTileMouseOut(data,event){
          //  console.log('on mouseout...!');
            this.setState({active:false});
            }
            toggleModal=()=>{
               // console.log('toggle modal');
                this.setState({showModal:!this.state.showModal})
            }
    render(){
        const {tileData} = this.props;
 
    let images = tileData.slideImg.map((img,i)=>{
        return {url:require('../../../images/'+tileData.imgContainer+'/'+img+'.jpg')}
    })
    //console.log(images,'imgssss')
    return(
        <div>
        {/* <a href={tileData.link} target='_blank'> */}
        <div className={this.state.active ? 'project-tile hover-tile':'project-tile'} 
                onMouseOver={(e)=>this.onTileMouseOver(tileData,e)}
                onMouseOut={(e)=>this.onTileMouseOut(tileData,e)}
                style={{backgroundImage:`url(${tileData.img})`}} onClick={this.toggleModal}>
                    {this.state.active ? <div className='tile-text'><div className='tile-title'>{tileData.name}</div><div>{tileData.hoverContent}</div></div>:null} 
        </div>
        {this.state.showModal ? (<Modal>
            <div className='modal-outer' onClick={this.toggleModal}></div>
            <div className='modal-inner'>
            <MediaQuery minWidth={600}>
                <SimpleImageSlider
                        width={855}
                        height={575}
                        images={images}
                        showNavs={true}
                        bgColor={'#252525'}
                        style={{width:"80%"}}
                    />
            </MediaQuery>
            
        <div className='close-btn' onClick={this.toggleModal}>
            <i style={{position:'fixed'}} className="fa fa-times"></i>
        </div>
        <div className='project-name'>{tileData.name}</div>
        <div className='project-info'>{tileData.projectInfo}</div>
        <a href={tileData.link} target='_blank' rel="noopener noreferrer">
            <div className='visit-btn'>Visit Site</div></a>   
            </div>
        </Modal>):null}
        {/* </a> */}
       </div>
    )
    }
}

export default ProjectTile;