import React from 'react';
import './HomePage.scss';
import PersonalInfo from './PersonalInfo/PersonalInfo';

class HomePage extends React.Component{
    constructor(){
        super();
        this.state={
            turnSurface:false
        }
    }
    btnClick=()=>{
        this.setState({turnSurface:true})
    }
    
    render(){
    return(
    <div className='home-comp'>
            <div className={this.state.turnSurface?'home-box-inner turn-surface':'home-box-inner'}>
                
                <div className='intro-text'>
                    <div className='innerDivFront'>
                        <div className='stars'></div>
                        <div className='twinkling'></div>
                        <div className='self-img'></div>
                        <div className='introText'>
                        Hello I'm Pranita Bhagat <br/>I'm Front End Web Developer<br/>
                        <button onClick={this.btnClick}>Click Here To Know More</button>
                        {/* {this.state.turnSurface ? <div className='startText'>Lets Go...</div>:null} */}
                        </div>
                    </div>
                    
                    
                </div>
                
                        <PersonalInfo turnSurface ={this.state.turnSurface} />
                
            </div>
    </div>)}
}

export default HomePage;